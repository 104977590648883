.row {
  display: flex;
  gap: 20px;
  @media (max-width: 720px) {
    flex-direction: column;
  }
}

.input {
  width: 100%;
}

.label {
  font-size: 14px;
  color: var(--light);
  margin-bottom: 5px;
  display: block;
}

.cardElementContainer {
  width: 100%;
  max-width: 500px;
}

.button {
  width: fit-content !important;
  height: fit-content;
  margin-top: auto;
  margin-right: auto;
  min-width: 116px;
}

.cardElement {
  background: rgba(52, 55, 65, 0.2);
  border: 2px solid #343741;
  padding: 15px;
  margin-right: 0;
  //   margin-bottom: 20px;
}

.form {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px;
  width: 100%;
}

.subHeader {
  color: var(--light);
  font-size: 24px;
  font-family: Rift-Bold;
  a {
    display: inline !important;
  }
}

.caption {
  margin-bottom: 20px;
  font-size: 12px;
  font-family: open-sans;
  color: var(--light);
  opacity: 0.7;
  & * {
    margin-bottom: 20px;
    font-size: 12px;
    font-family: open-sans;
    color: var(--light);
    opacity: 1;
  }
}

.text {
  font-family: Rift-Bold;
  color: var(--light);
  text-align: center;
  display: flex;
  font-size: 24px;
  margin: auto;
  & > * {
    font-family: Rift-Bold;
  }
  @media (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
  }
}
