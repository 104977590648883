.container {
  padding: 40px;
  background-color: var(--grey);
  text-align: center;
  display: flex;
  flex-direction: column;
  .priceMain {
    font-size: 90px;
    font-family: Rift-Bold;
    color: var(--orange);
    height: fit-content;
    line-height: 95px;
  }
  .dec {
    font-size: 40px;
    font-family: Rift-Bold;
    color: var(--orange);
    height: fit-content;
    line-height: 50px;
  }
  .hr {
    background-color: var(--light);
    opacity: 0.15;
    height: 0.5px;
    border: none;
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .ul {
    margin-top: 20px;
    margin-bottom: 30px;
    & > :not(:last-child) {
      margin-bottom: 20px;
    }
    li {
      font-family: Open-Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: var(--light);
      display: flex;
      align-items: center;
      justify-content: center;
      &::before {
        content: "";
        display: block;
        height: 8px;
        width: 8px;
        background-color: var(--orange);
        margin-right: 10px;
        border-radius: 100%;
      }
    }
  }
}
