.container {
  padding: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--orange);
  background-image: url("../../../assets/images/backgrounds/pubgbg-1.png"),
    url("../../../assets/images/abstract/triangles.png");
  background-repeat: no-repeat, no-repeat;
  background-position: bottom, left bottom;
  background-size: 100% 100%;
  h3 {
    font-size: 42px;
    line-height: 45px;
    display: flex;
    align-items: center;
    font-family: Rift-Bold;
    color: #ffffff;
    text-shadow: 0px 0px 32px rgba(236, 181, 81, 0.3);
  }
  p {
    text-shadow: 0px 0px 32px rgba(236, 181, 81, 0.3);
    font-family: Open-Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-top: 5px;
  }
}

.fancy {
  margin-top: auto;
}

.button {
  //   background: rgba(90, 192, 236, 0.9) !important;
  /* fantasy */

  //   border: 2px solid #5ac0ec !important;
  width: fit-content !important;
  height: 40px;
  margin-top: 20px;
}
